import React, { useContext } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import { PttFieldType } from "../../types";

interface PasswordProps extends PttFieldType {
  mode?: string;
  hideRepeat?: boolean;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

const Password = ({ field }: PasswordProps) => {
  const { getLiteral } = useContext(GlobalContext);
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext);

  const language = getLanguage({ field });
  const value = getValue({ field });
  const valueRepeated = getValue({
    field: { ...field, name: `${field.name}_repeated` },
  });
  const errors = getErrors({ field });

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = e.target.value;
    updateStored(field, value);
  };

  const onChangeRepeatedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    valueRepeated[language] = e.target.value;
    updateStored(
      {
        ...field,
        name: `${field.name}_repeated`,
        validations: field?.validations?.filter((i) => i !== "password"),
      },
      valueRepeated
    );
    updateStored(field, value);
  };

  const input = (
    <input
      name={field.name}
      className={`w-full px-2 py-1 border border-grayLight ${
        showErrors && errors.length > 0 ? "border-error" : ""
      } outline-none placeholder-gray placeholder-opacity-50`}
      type="password"
      placeholder={field.options.label}
      value={`${value && value[language] ? value[language] : ""}`}
      onChange={onChangeText}
    />
  );

  const inputRepeated = (
    <input
      name={`${field.name}_repeated`}
      className={`w-full px-2 py-1 border border-grayLight ${
        showErrors && errors.length > 0 ? "border-error" : ""
      } outline-none placeholder-gray placeholder-opacity-50`}
      type="password"
      placeholder={field.options.label}
      value={`${
        valueRepeated && valueRepeated[language] ? valueRepeated[language] : ""
      }`}
      onChange={onChangeRepeatedText}
    />
  );

  return (
    <div className={`${field.options?.hideRepeat === true ? "" : "md:flex"}`}>
      <div className="flex-1 mb-8 md:mb-0">
        <label className="text-xs font-light uppercase">
          {getLiteral(field.options.label)}
        </label>
        {input}
        {showErrors && errors.length > 0 && (
          <p className="text-error">{errors[0]}</p>
        )}
      </div>
      {field.options?.hideRepeat === true || (
        <div className="flex-1 md:ml-4">
          <label className="text-xs font-light uppercase">
            {getLiteral(field.options.label)}
          </label>
          {inputRepeated}
        </div>
      )}
    </div>
  );
};

export default Password;
