import { useContext } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { GlobalContext } from "../contexts/GlobalContext"
import Login from "./Login"
import Main from "./Main"
import Recovery from "./Recovery"
import Reset from "./Reset"
import ResetResult from "./ResetResult"
import SetPassword from "./SetPassword"

const Router = () => {

  const { appConfig } = useContext(GlobalContext)

  if (!appConfig) {
    return (
      <div className="flex items-center justify-center h-screen w-sreen">
        <img className="w-6 h-6" src="/assets/images/spinner.gif" alt="Loading" />
      </div>
    )
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/recovery" element={<Recovery />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/set-password/:token" element={<SetPassword />} />
          <Route path="/result" element={<ResetResult />} />
          <Route path="/admin*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Router
