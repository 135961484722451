import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";

const SetPassword = () => {
  const { getLiteral } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "newPassword") {
      setNewPassword(event.target.value);
    } else if (event.target.name === "confirmPassword") {
      setConfirmPassword(event.target.value);
    }
  };

  const send = async () => {
    setErrorMessage("");
    if (!newPassword || !confirmPassword) {
      setErrorMessage("Please fill in both password fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("The passwords do not match.");
      return;
    }
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}session/set-password`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: newPassword,
          newPassword: confirmPassword,
        }),
      }
    );

    if (res.status === 200) {
      navigate(`/login`, { replace: true });
    } else {
      setLoading(false);
      const data = await res.json();
      setErrorMessage(data.error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2">
        <div>
          <h2 className="mb-4 text-xl font-semibold text-center">
            {getLiteral("set_password_title")}
          </h2>
          <div>
            <div className="mb-4">
              <label htmlFor="newPassword">
                {getLiteral("set_password_new_password")}
              </label>
              <input
                className="w-full px-2 py-1 placeholder-opacity-50 rounded outline-none bg-grayLight placeholder-gray"
                id="newPassword"
                type="password"
                placeholder={getLiteral("set_password_new_password")}
                value={newPassword}
                onChange={handlePasswordChange}
                name="newPassword"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword">
                {getLiteral("set_password_confirm_new_password")}
              </label>
              <input
                className="w-full px-2 py-1 placeholder-opacity-50 rounded outline-none bg-grayLight placeholder-gray"
                id="confirmPassword"
                placeholder={getLiteral("set_password_confirm_new_password")}
                type="password"
                value={confirmPassword}
                onChange={handlePasswordChange}
                name="confirmPassword"
              />
            </div>
            {errorMessage && (
              <span className="block my-4 text-error">{errorMessage}</span>
            )}
          </div>
          <div className="flex items-end justify-center">
            {loading ? (
              <span className="font-semibold">{getLiteral("loading")}</span>
            ) : (
              <button onClick={send} className="btn-primary">
                {getLiteral("set_password_cta")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-end mt-2 mb-8">
        <Link
          to={`/login`}
          className="text-xs font-semibold hover:text-primary"
        >
          {getLiteral("cancel")}
        </Link>
      </div>
    </div>
  );
};
export default SetPassword;
