import {
  EditDataContext,
  useEditDataContext,
} from "../contexts/EditDataContext"
import { GlobalContext, useGlobalContext } from "../contexts/GlobalContext"
import Router from "./Router"

const App = () => {
  const editDataInitValue = useEditDataContext()
  const globalInitValue = useGlobalContext()

  return (
    <>
      <GlobalContext.Provider value={globalInitValue}>
        <EditDataContext.Provider value={editDataInitValue}>
          <Router />
        </EditDataContext.Provider>
      </GlobalContext.Provider>
    </>
  )
}

export default App
