import { useState, useEffect, useRef } from "react";

function useImageLoaded(src: string) {
  const maxAttempts = useRef(5);
  const [loaded, setLoaded] = useState(false);
  const [attempt, setAttempt] = useState(0);

  useEffect(() => {
    const loadImage = () => {
      const mainImage = new Image();

      mainImage.onload = () => {
        setLoaded(true);
      };

      mainImage.onerror = () => {
        if (attempt < maxAttempts.current) {
          setTimeout(() => {
            setAttempt(attempt + 1);
          }, 3000 * (attempt + 1));
        } else {
          setLoaded(true);
        }
      };

      mainImage.src = src;

      if (mainImage.complete) {
        setLoaded(true);
      }
    };

    if (attempt < maxAttempts.current && !loaded) {
      loadImage();
    }
  }, [src, attempt, loaded]);

  return loaded;
}

export default useImageLoaded;
