import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttModelField } from "../../types";
import Field from "./Field";

interface UrlFieldType extends PttModelField {
  defaultValues: { [key: string]: unknown };
  options: {
    label: string;
    placeholder?: string;
    useTitle?: boolean;
    defaultValues?: { [key: string]: unknown };
  };
}

const slugify = (text: string) => {
  const parsedText =
    text && typeof text === "string"
      ? text
          .normalize("NFD") // split an accented letter in the base letter and the acent
          .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
          .replace(/[^\w\s\-]/gi, "") // Remove characters that are not including a-z, A-Z, 0-9, (_, -)
          .toLowerCase()
          .trim()
          .replace(/\s+/g, "-")
      : null;
  return parsedText;
};

const Url = ({ field }: { field: UrlFieldType }): React.ReactElement => {
  const {
    updateStored,
    getValue,
    getErrors,
    getLanguage,
    getLanguages,
    showErrors,
  } = useContext(EditDataContext);

  const languages = getLanguages();
  const language = getLanguage({ field });
  const value = getValue({ field });
  const errors = getErrors({ field });

  const titleValue = getValue({
    field: {
      name: "title",
      type: "text",
      options: {
        label: "Title",
      },
    },
  });

  const customUrlValue = getValue({
    field: {
      name: "customUrl",
      type: "text",
      options: {
        label: "Custom URL",
      },
      parentField: {
        name: "publication",
        type: "model",
        options: {
          label: "Publication",
        },
      },
    },
  });

  const showLanguage =
    field?.options?.defaultValues?.showLanguage ||
    (field?.parentField as UrlFieldType).options?.defaultValues?.showLanguage;
  const useTitle =
    field?.options?.defaultValues?.useTitle ||
    (field?.parentField as UrlFieldType).options?.defaultValues?.useTitle;
  const prefixUrl =
    field?.options?.defaultValues?.prefixUrl ||
    (field?.parentField as UrlFieldType).options?.defaultValues?.prefixUrl;

  const [editMode, setEditMode] = useState<null | Boolean>(
    typeof useTitle === "undefined" || customUrlValue?.default ? true : false
  );

  useEffect(() => {
    setEditMode(
      typeof useTitle === "undefined" || customUrlValue?.default ? true : false
    );
  }, [customUrlValue, field]);

  const updateSlug = (slug: string | { [key: string]: unknown }) => {
    const updatedValue = { ...value };

    if (typeof slug === "string") {
      updatedValue[language] = slug.replace(/^[\\/]+|[\\/]+$/g, "");
    } else if (slug) {
      const realSlug =
        slug &&
        slug?.default &&
        slug?.default !== null &&
        (slug.default as { [key: string]: unknown }).constructor.name ===
          "Object"
          ? (slug.default as { [key: string]: string })
          : (slug as { [key: string]: string });
      Object.keys(languages).forEach((lang) => {
        if (realSlug[lang]) {
          updatedValue[lang] = realSlug[lang].replace(/^[\\/]+|[\\/]+$/g, "");
        }
      });
    }

    Object.keys(updatedValue).forEach((lang) => {
      if (updatedValue[lang]) {
        updatedValue[lang] = slugify(updatedValue[lang]);
      }
    });

    if (JSON.stringify(updatedValue) !== JSON.stringify(value)) {
      updateStored(field, updatedValue);
    }
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSlug(e.target.value);
  };

  useEffect(() => {
    if (useTitle && !editMode) {
      updateSlug(titleValue);
    }
  }, [titleValue, editMode]);

  return (
    <div className="relative">
      {useTitle ? (
        <div className="absolute bottom-0 pb-1 cursor-pointer right-4 font-primary">
          {editMode ? "Using custom URL" : "URL generated automatically"}
        </div>
      ) : null}
      <Field field={field}>
        <div
          className={`w-full flex
          } px-2 py-1 ${
            showErrors && errors.length > 0 ? "border border-error" : ""
          } bg-white border border-grayLight placeholder-gray placeholder-opacity-50 font-mono`}
        >
          /{showLanguage ? <>{language}/</> : null}
          {prefixUrl &&
          ((prefixUrl as { [key: string]: string })[language] as string) ? (
            <>{(prefixUrl as { [key: string]: string })[language] as string}/</>
          ) : null}
          <input
            className={`w-full pr-2 ${
              editMode ? "bg-white" : "bg-transparent"
            } outline-none`}
            name={field.name}
            type="text"
            placeholder={
              editMode
                ? field?.options?.placeholder || field?.options?.label
                : "edit title to change"
            }
            value={`${value[language] || ""}`}
            onChange={onChangeText}
            disabled={!editMode}
          />
        </div>
      </Field>
    </div>
  );
};

export default Url;
