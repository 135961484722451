import { useContext, useState } from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttModelField } from '../../types'
import Field from './Field'

interface TextareaType extends PttModelField {
  options: {
    label: string
    markdown: boolean
  }
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const listCommands = [[
  'bold',
  'italic',
  'link',
  'unordered-list',
  'ordered-list',
]]

const Textarea = ({
  field,
}: {
  field: TextareaType
}) => {

  const { updateStored, getValue, getErrors, getLanguage, showErrors } = useContext(EditDataContext)

  const language = getLanguage({ field })
  const value = getValue({ field })
  const errors = getErrors({ field })

  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')

  const onChangeText = (v: string) => {
    value[language] = v
    updateStored(field, value)
  }

  const textarea = field?.options?.markdown ? (
    <div
      className={`border border-grayLight ${showErrors && errors.length > 0 ? 'border border-error' : ''
        }`}
    >
      <ReactMde
        toolbarCommands={listCommands}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={() => Promise.resolve(converter.makeHtml(`${value[language] || ''}`))
        }
      />
    </div>
  ) : (
    <div>
      <textarea
        name={field?.name}
        className={`border border-grayLight w-full h-32 px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
          } outline-none resize-y`}
        placeholder={field?.options?.label}
        value={`${value[language] || ''}`}
        onChange={e => onChangeText(e.target.value)}
      />
    </div>
  )

  return (
    <Field
      field={field}
    >
      {textarea}
    </Field>
  )
}

export default Textarea
