import React from 'react'
import Wildcard from './Wildcard'
import {PttModelField} from '../../types'

type FormProps = {
  name: string 
  parentIsSorting?: boolean,
  parentField?: PttModelField,
  fields: PttModelField[]
}

const Form = ({
  name,
  parentIsSorting = false,
  parentField,
  fields,
}: FormProps) => (
  <div className="grid grid-cols-4 gap-4">
    {fields.map((item: PttModelField) => (
      <Wildcard
        field={{
          ...item,
          modelName: name,
          parentField,
        }}
        key={item.name}
        // parentIsSorting={parentIsSorting}
      />
    ))}
  </div>
)

export default Form
