import React, { useContext } from "react";
import LanguageSelector from "./LanguageSelector";
import { PttModelField } from "../../types";
import { GlobalContext } from "../../contexts/GlobalContext";

type FieldProps = {
  field: PttModelField;
  children: React.ReactNode;
  hideLabel?: boolean;
};

export const Label = ({ field }: { field: PttModelField }) => {
  const { getLiteral } = useContext(GlobalContext);
  return (
    <>
      <label
        className={`font-light uppercase text-xs ${
          field.type === "checkbox" ? "" : "block"
        }`}
      >
        {getLiteral(
          (field?.options as { [key: string]: unknown }).label as string,
          "manager_field_"
        ) || ""}
      </label>
    </>
  );
};

const Field = ({ field, children, hideLabel = false }: FieldProps) => {
  return (
    <div>
      {field.type !== "model" && !hideLabel && <Label field={field} />}
      {field?.translatable ? (
        <LanguageSelector>{children}</LanguageSelector>
      ) : (
        children
      )}
    </div>
  );
};

export default Field;
