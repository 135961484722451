import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import Button from "../components/Button";
import Container from "../components/Container";
import Form from "../components/fields/Form";
import { EditDataContext } from "../contexts/EditDataContext";
import { GlobalContext } from "../contexts/GlobalContext";
import fetchAPI from "../lib/fetchAPI";
import { StoredDataType } from "../types";
import ActionsTab from "../components/ActionsTab";

type BodyDataType = {
  modelName: string;
  _id?: string;
};

const Edit = () => {
  const {
    name,
    setName,
    data,
    setData,
    mode,
    setMode,
    ready,
    storedData,
    getValue,
    hasErrors,
    setShowErrors,
    updateStored,
    setStoredData,
  } = useContext(EditDataContext);

  const { getLiteral, getToken, logout, getUser } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { name: modelName, id: urlId } = useParams();

  const [title, setTitle] = useState(name);
  const [saving, setSaving] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [saved, setSaved] = useState(false);
  const [activeTab, setActiveTab] = useState("information");
  const user = getUser();

  const titleValue = getValue({
    field: {
      name: "title",
      type: "text",
      options: {
        label: "Title",
      },
    },
  });

  useEffect(() => {
    if (name && ready) {
      if (titleValue?.default && typeof titleValue?.default === "string") {
        setTitle(titleValue.default);
      } else if (titleValue?.en) {
        setTitle(titleValue.en);
      } else if (titleValue?.default?.en) {
        setTitle(titleValue.default?.en);
      } else {
        setTitle(
          `${mode === "edit" ? "Edit" : "Create"} ${data?.modelConfig?.title}`
        );
      }
    }
  }, [mode, titleValue]);

  const get = async () => {
    const body: BodyDataType = {
      modelName: modelName!,
    };

    if (urlId) {
      body._id = urlId;
    }

    const { valid, data } = await fetchAPI({
      url: "model/get",
      body,
      token: getToken(),
    });

    if (!valid) logout();

    setMode(urlId ? "edit" : "create");
    setName(modelName as string);
    setData(data);
  };

  useEffect(() => {
    get();
  }, [modelName, urlId, navigate]);

  const makePublicAndSave = async () => {
    const field = {
      name: "status",
      type: "select",
      options: {
        label: "Status",
      },
      parentField: {
        name: "publication",
        type: "model",
        options: {
          label: "Publication",
        },
      },
    };
    updateStored(field, { default: "public" });
    save();
  };

  const save = async (type = "default") => {
    setShowPublishModal(false);

    if (hasErrors()) {
      setShowErrors(true);
      return;
    }

    if (!storedData) {
      return;
    }

    setShowErrors(false);
    setSaving(true);

    const { valid, data } = await fetchAPI({
      url: "model/update",
      method: "PUT",
      body: { ...storedData, modelName },
      token: getToken(),
    });

    if (!valid) logout();

    if (typeof urlId === "undefined") {
      navigate(`/admin/edit/${modelName}/${data._id}`, {
        state: { prevPath: "edit" },
      });
    } else {
      setStoredData({ ...(data as StoredDataType) });
      setSaved(true);
      setTimeout(() => setSaved(false), 3000);
    }

    setSaving(false);
  };

  const createModelTransformer = async () => {
    if (!storedData) {
      return;
    }

    if (!window.confirm(`Create a model transformer for ${storedData.title}?`))
      return;

    await fetchAPI({
      url: "model/create-model-transformer",
      method: "POST",
      body: { modelName: storedData.title },
      token: getToken(),
    });
  };

  const getTabs = () => {
    const tabs: string[] = ["information"];
    if (data?.models && name && data?.models[name as string]) {
      const fields = data?.models[name];

      const hasLiterals = fields.find((field) => field.name === "literals");
      if (hasLiterals) {
        tabs.push("literals");
      }

      const hasPublication = fields.find(
        (field) => field.name === "publication"
      );
      if (hasPublication) {
        if (hasPublication?.showIf) {
          let valid = false;
          Object.keys(
            hasPublication?.showIf as { [key: string]: unknown }
          ).forEach((key) => {
            if (
              storedData &&
              storedData[key] ===
                (hasPublication?.showIf as { [key: string]: unknown })[key]
            ) {
              valid = true;
            }
          });
          if (valid) {
            tabs.push("publication");
          }
        } else {
          tabs.push("publication");
        }
      }

      const hasSeo = fields.find((field) => field.name === "seo");
      if (hasSeo) {
        tabs.push("seo");
      }

      if (urlId) {
        tabs.push("actions");
      }
    }
    return tabs;
  };

  const getFields = (tab: string) => {
    const fields = data?.models[name as string];
    if (fields) {
      if (tab === "publication") {
        return fields.filter((field) => field.name === "publication");
      } else if (tab === "literals") {
        return fields.filter((field) => field.name === "literals");
      } else if (tab === "seo") {
        return fields.filter((field) => field.name === "seo");
      } else if (tab === "actions") {
        return [];
      } else {
        return fields.filter(
          (field) => !["publication", "seo", "literals"].includes(field.name)
        );
      }
    }
    return [];
  };

  if (!ready) {
    return (
      <div className="relative h-full pb-12">
        <div className="pt-12 pb-8">
          <Container>
            <span className="text-gray">Loading...</span>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div className="relative h-full pb-12 bg-grayLighter">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="sticky top-0 z-10 pb-4 bg-white">
        <Container>
          <>
            <div className="flex items-center justify-between">
              <div className="w-2/3 md:w-1/2">
                <h1 className="font-medium text-xxl">{title}</h1>
                {storedData &&
                  ready &&
                  (storedData?.publication as { [key: string]: unknown }) &&
                  ((storedData.publication as { [key: string]: unknown })
                    .status as string) && (
                    <>
                      <div className="text-xs uppercase">
                        <span className="text-sm">{getLiteral("status")}:</span>
                        <span className="ml-2 text-sm">
                          {getLiteral(
                            (
                              storedData.publication as {
                                [key: string]: unknown;
                              }
                            ).status as string
                          )}
                        </span>
                      </div>
                    </>
                  )}
                {storedData &&
                  ready &&
                  user?.role === "admin" &&
                  data?.stored.locked === true && (
                    <>
                      <div className="text-xs uppercase">
                        <span className="text-sm">
                          {getLiteral("locked_text")}
                        </span>
                      </div>
                    </>
                  )}
              </div>
              {ready && (
                <div>
                  <div className="flex items-center">
                    {saved && (
                      <span className="mr-4 text-xs text-gray">
                        {getLiteral("saved_successfully")}
                      </span>
                    )}
                    <div>
                      <Button
                        theme="dark"
                        loading={saving}
                        onClick={() => {
                          if (
                            data &&
                            data?.modelConfig?.hasPublication &&
                            mode === "create"
                          ) {
                            if (
                              !storedData ||
                              (
                                storedData?.publication as {
                                  [key: string]: string;
                                }
                              ).status !== "public"
                            ) {
                              setShowPublishModal(true);
                              return;
                            }
                          }
                          save();
                        }}
                      >
                        {getLiteral("save")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </Container>
      </div>
      {data?.fileInfo && (
        <div className="pt-4 pb-8 bg-white">
          <Container>
            <div className="p-4 border border-gray">
              <div className="flex items-center justify-between">
                <h2 className="font-medium text-md">
                  {getLiteral("model_transformer")}{" "}
                </h2>
                <div>
                  <Button
                    theme="dark"
                    onClick={async () => {
                      if (!data?.fileInfo?.exists) {
                        await createModelTransformer();
                      }
                      window.open(data?.fileInfo?.vscodeLink);
                    }}
                  >
                    {data?.fileInfo.exists
                      ? "Edit in VSCode"
                      : "Create in VSCode"}
                  </Button>
                </div>
              </div>
              {!data?.fileInfo.exists && (
                <>
                  <p>{getLiteral("model_transformer_does_not_exist")}</p>
                </>
              )}
              {data?.fileInfo.exists && (
                <>
                  <pre className="p-4 mt-4 text-xs bg-grayLighter roudned">
                    {data?.fileInfo?.contents}
                  </pre>
                </>
              )}
            </div>
          </Container>
        </div>
      )}
      <div className="flex-1 h-full">
        {data?.errors && (
          <>
            <div className="pb-8 bg-white errors">
              <Container>
                <>
                  {data?.errors.map((error) => (
                    <div key={error} className="px-4 py-2 bg-error">
                      {getLiteral(error)}
                    </div>
                  ))}
                </>
              </Container>
            </div>
          </>
        )}
        {data && ready && name && modelName === name && (
          <>
            <div className="pt-4 bg-white border-b border-grayLight">
              <Container>
                <div className="flex">
                  {getTabs().map((tab) => (
                    <div
                      key={tab}
                      className={`cursor-pointer hover:border-black hover:border-b mr-8 text-md pb-1 font-medium -mb-px ${
                        tab === activeTab ? "border-b border-black" : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {getLiteral(tab) as string}
                    </div>
                  ))}
                </div>
              </Container>
            </div>
            <div className="pt-12">
              <Container>
                <>
                  {getTabs().map((tab) => (
                    <div
                      key={tab}
                      className={`${tab === activeTab ? "" : "hidden"}`}
                    >
                      {tab === "actions" &&
                        typeof storedData?._id !== "undefined" && (
                          <ActionsTab
                            id={storedData?._id as string}
                            modelName={modelName}
                            url={data?.url as { [key: string]: string }}
                            get={get}
                          />
                        )}
                      <Form fields={getFields(tab)} name={name} />
                    </div>
                  ))}
                </>
              </Container>
            </div>
          </>
        )}
      </div>
      {showPublishModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full z-90 bg-blackOpacity">
          <div className="w-4/5 p-4 bg-white md:w-2/5">
            <h2 className="text-xl">{getLiteral("publication")}</h2>
            <p className="mb-2">{getLiteral("publish_and_save_text")}</p>
            <div className="flex">
              <div className="mr-2">
                <Button theme="dark" onClick={makePublicAndSave}>
                  {getLiteral("publish_and_save")}
                </Button>
              </div>
              <Button theme="outline-dark" onClick={save}>
                {getLiteral("just_save")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;
