import React, { useRef, useContext } from "react";
import Form from "./Form";
import { PttModelField, PttModelType } from "../../types";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { EditDataContext } from "../../contexts/EditDataContext";
import { GlobalContext } from "../../contexts/GlobalContext";

type Props = {
  sorting: boolean;
  index: number;
  modelsAvailable: { [key: string]: string };
  deleteModel: (index: number) => void;
  item: PttModelType;
  moveModel: (dragIndex: number, hoverIndex: number) => void;
  parentField: PttModelField;
  deletable: Boolean;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ModelsRow = ({
  item,
  deleteModel,
  modelsAvailable,
  index,
  sorting,
  moveModel,
  parentField,
  deletable = true,
}: Props) => {
  const { data } = useContext(EditDataContext);
  const { getLiteral } = useContext(GlobalContext);

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveModel(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: "CARD",
    item: () => {
      return { id: item.key, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => sorting,
  });

  drag(drop(ref));

  if (!data || !data.models || !data.find) return null;

  const { models } = data;

  return (
    <div ref={ref} className={`p-4 mt-2 bg-white`}>
      <div
        className={`${
          sorting ? "cursor-move max-h-72 overflow-hidden" : ""
        } relative`}
      >
        <div
          className={`flex items-center justify-between mb-4 text-xs font-medium uppercase select-none border-bottom`}
        >
          <span>{modelsAvailable[item.modelName]}</span>
          {!sorting && deletable && (
            <span
              className="text-xs font-normal normal-case cursor-pointer"
              onClick={() => deleteModel(index)}
            >
              {getLiteral("delete")}
            </span>
          )}
        </div>
        {models[item.modelName]?.length > 0 && (
          <div
            className={`${
              sorting ? "h-30 overflow-scroll pointer-events-none" : ""
            }`}
          >
            <Form
              parentIsSorting={sorting}
              key={item._id}
              name={item.modelName}
              fields={models[item.modelName]}
              parentField={{
                ...parentField,
                index,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelsRow;
