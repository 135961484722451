import { Link } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import { useContext } from "react";

const Actions = ({
  modelName,
  id,
  page = "",
}: {
  modelName: string;
  id: string;
  page: string;
}) => {
  const { getLiteral } = useContext(GlobalContext);

  return (
    <div className="relative flex text-xs text-gray">
      <Link
        className="mr-4 cursor-pointer hover:text-primary"
        to={{
          pathname: `/admin/edit/${modelName}/${id}?page=${page}`,
        }}
      >
        {getLiteral("edit")}
      </Link>
    </div>
  );
};

export default Actions;
