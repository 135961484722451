import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Form from "../components/fields/Form";
import { EditDataContext } from "../contexts/EditDataContext";
import { GlobalContext } from "../contexts/GlobalContext";

const Reset = () => {
  const { storedData, hasErrors, setShowErrors, setData, data, setMode } =
    useContext(EditDataContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { getLiteral } = useContext(GlobalContext);

  useEffect(() => {
    setMode("edit");
    setData({
      stored: {
        modelName: "Login",
      },
      languages: {
        en: "en",
      },
      models: {
        Login: [
          {
            name: "token",
            type: "text",
            options: {
              label: "Recovery code",
              style: {
                width: 4,
              },
            },
            validations: ["not_blank"],
          },
          {
            name: "password",
            type: "password",
            options: {
              label: "New Password",
              hideRepeat: true,
            },
            validations: ["not_blank", "password_min_length"],
          },
        ],
      },
    });
  }, [setData, setMode]);

  const send = async () => {
    if (hasErrors()) {
      setShowErrors(true);
      return;
    }

    if (!storedData) {
      return;
    }

    setShowErrors(false);
    setLoading(true);

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}session/reset-password`,
      {
        method: "POST",
        body: JSON.stringify({
          password: storedData.password,
          token: storedData.token,
        }),
      }
    );

    const responseData = await res.json();

    if (res.status !== 200) {
      setErrorMessage(responseData.error);
      return;
    }

    navigate("/result", { replace: true });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h1 className="text-xl font-medium">
          {getLiteral("reset_password_title")}
        </h1>
        <h2 className="font-light text-gray">
          {getLiteral("reset_password_text")}
        </h2>

        <div className="my-8">
          {data && <Form fields={data.models.Login} name={"Login"} />}
        </div>

        {errorMessage && <div className="mb-4 text-red">{errorMessage}</div>}

        <div className="items-center justify-between md:flex">
          <Button onClick={send} theme="dark" loading={loading}>
            {getLiteral("reset_password_cta")}
          </Button>
          <Link to={`/login`} className="text-xs cursor-pointer text-gray">
            {getLiteral("cancel")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Reset;
