import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";

const ResetResult = () => {
  const { getLiteral } = useContext(GlobalContext);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-4 text-center bg-white md:w-1/2">
        <div>
          <h2 className="mb-4 text-xl">
            {getLiteral("reset_password_success_title")}
          </h2>
          <span>{getLiteral("reset_password_success_text")}</span>
          <div className="flex items-end justify-center mt-4">
            <Link
              to={`/login`}
              className="text-xs cursor-pointer hover:text-primary"
            >
              {getLiteral("reset_password_success_cta")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetResult;
