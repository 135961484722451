/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import upload from "../../lib/upload";
import { AppConfig, PttFieldType } from "../../types";
import Field, { Label } from "./Field";
import MediaViewer from "./MediaViewer";
import Wildcard from "./Wildcard";
import useImageLoaded from "../../hooks/useImageLoaded";

interface ImageProps extends PttFieldType {
  options: {
    hideAlt?: boolean;
  };
}

const Image = ({ field }: ImageProps) => {
  const { updateStored, storedData, getValue, getLanguage } =
    useContext(EditDataContext);
  const { appConfig, getToken } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({ field });

  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showMediaView, setShowMediaViewer] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    value && value[language]
      ? (value[language] as { [key: string]: string }).url
      : null
  );
  const [ready, setReady] = useState(value && value[language] ? true : false);

  const updateValue = (v: { [key: string]: unknown } | null) => {
    value[language] = v;
    updateStored(field, value);
    setShowMediaViewer(false);
  };

  const remove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Confirm?")) {
      updateValue(null);
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(false);

    if (e.dataTransfer.files.length === 0) return;

    setUploading(true);

    const token = getToken();
    if (!token) {
      return;
    }

    const fileInfo = await upload(
      "image",
      token as string,
      appConfig as AppConfig,
      e.dataTransfer.files[0]
    );

    if (!fileInfo) {
      setUploading(false);
    }

    if (value?.default?.alt && fileInfo) {
      fileInfo.alt = value.default.alt;
    }
    updateStored(field, fileInfo as { [key: string]: unknown });

    let timer = 0;
    const imageIsAvailable = setInterval(async () => {
      const res = await fetch(
        (fileInfo as { [key: string]: unknown }).previewUrl as string
      );
      if (res.status === 200) {
        setReady(true);
        clearInterval(imageIsAvailable);
        setUploading(false);
      } else {
        timer++;
        if (timer > 5) {
          setUploading(false);
          clearInterval(imageIsAvailable);
          alert("Upload request timeout");
        }
      }
    }, 3000);
  };

  useEffect(() => {
    if (value[language]) {
      setImageUrl(
        (value[language] as { [key: string]: unknown }).previewUrl as string
      );
    } else {
      setImageUrl(null);
    }
    setReady(true);
  }, [value]);

  const ImagePreview = ({ src }: { src: string }) => {
    const imageLoaded = useImageLoaded(src);
    if (!imageLoaded) return null;
    return (
      <img
        className="absolute inset-0 object-cover w-full h-full"
        alt="Preview"
        src={src}
      />
    );
  };

  return (
    <div>
      <Label field={field} />
      <div className="p-4 border border-grayLight">
        <Field hideLabel={true} field={field}>
          {!field?.options?.hideAlt && (
            <div className="mb-4">
              <Wildcard
                field={{
                  type: "text",
                  name: "alt",
                  translatable: true,
                  options: {
                    label: "image_alt",
                  },
                  modelName: field.modelName,
                  parentField: field,
                }}
                key={"alt"}
              />
            </div>
          )}
          <div>
            <div
              className="relative cursor-pointer"
              onDragEnter={() => setDragging(true)}
              onDragLeave={() => setDragging(false)}
              onDragOver={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                onDrop(e);
                setDragging(false);
              }}
            >
              <div
                className="relative w-48 h-48 overflow-hidden bg-white border cursor-pointer border-grayLight"
                onClick={() => {
                  if (!uploading) {
                    setShowMediaViewer(true);
                  }
                }}
              >
                {imageUrl && ready && <ImagePreview src={imageUrl} />}
                {uploading && (
                  <img
                    className="absolute w-6 h-6 -mt-3 -ml-3 left-24 top-24"
                    alt="Preview"
                    src={`${process.env.PUBLIC_URL}/assets/images/spinner.gif`}
                  />
                )}
              </div>
              <div
                className={`${dragging ? "" : ""} text-xs`}
                onClick={() => {
                  if (!uploading) {
                    setShowMediaViewer(true);
                  }
                }}
              >
                <div className="mt-2 pointer-events-none">
                  {uploading && "Uploading..."}
                  {!uploading && !dragging && (
                    <span>
                      Drop image here
                      <span className="block text-xxs">
                        or view media gallery
                      </span>
                    </span>
                  )}
                  {dragging && !uploading && <span>Drop to upload</span>}
                </div>
                {imageUrl && (
                  <div onClick={remove} className="mt-2 text-xs">
                    Delete
                  </div>
                )}
              </div>
              {showMediaView && (
                <MediaViewer
                  type="image"
                  value={value ? value[language] : null}
                  onChange={(v: { [key: string]: unknown }) => {
                    if (value?.default?.alt && v) {
                      v.alt = value.default.alt;
                    }
                    updateValue(v);
                  }}
                  onClose={() => setShowMediaViewer(false)}
                />
              )}
            </div>
          </div>
        </Field>
      </div>
    </div>
  );
};

export default Image;
