import { createContext, useEffect, useState } from "react";
import { AppConfig, PttUser } from "../types";
import useLocalStorage from "../hooks/useLocalStorage";
import jwt_decode from "jwt-decode";
import fetchAPI from "../lib/fetchAPI";
// import { useNavigate } from "react-router-dom"

type GlobalContextType = {
  getToken: () => string | undefined;
  setToken: (token: string) => void;
  appConfig?: AppConfig;
  setAppConfig: (config: AppConfig) => void;
  logout: () => void;
  getUser: () => PttUser | undefined;
  getLiteral: (ref: string, prefix?: string) => string | undefined;
};

type PttDecodedToken = {
  exp: number;
};

export function useGlobalContext(): GlobalContextType {
  const [appConfig, setAppConfig] = useState<AppConfig>();
  const [token, setToken] = useLocalStorage("token", undefined);

  const logout = () => {
    setToken(undefined);
    setAppConfig(undefined);
    window.location.href = "/login";
  };

  const getUser = () => {
    if (!token) return undefined;
    return appConfig?.user;
  };

  const getToken = () => {
    if (!token) return undefined;

    const decoded = jwt_decode<PttDecodedToken>(token);
    const isValid = decoded && Date.now() < decoded?.exp * 1000;

    if (!isValid) {
      logout();
      return undefined;
    }

    return token;
  };

  const getLiteral = (ref: string, prefix?: String) => {
    const realRef = `${prefix || "manager_"}${ref}`;
    if (!appConfig || !appConfig?.literals) return ref;
    return appConfig?.literals[realRef] || ref;
  };

  const getAppConfig = async () => {
    const { valid, data } = await fetchAPI({
      url: "config",
      method: "GET",
      token: getToken(),
      isPublic: true,
    });

    if (!valid) logout();

    setAppConfig(data);
  };

  useEffect(() => {
    if (!appConfig && !token) {
      getAppConfig();
    } else if (token) {
      getAppConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    appConfig,
    setAppConfig,
    getToken,
    setToken,
    logout,
    getUser,
    getLiteral,
  };
}

const GlobalContextValue: GlobalContextType = {
  appConfig: undefined,
  setAppConfig: () => {},
  setToken: () => {},
  logout: () => {},
  getUser: () => undefined,
  getToken: () => undefined,
  getLiteral: () => undefined,
};

export const GlobalContext =
  createContext<GlobalContextType>(GlobalContextValue);
