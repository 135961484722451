import React from 'react'

const Container = ({ className, children }: {className?: String, children: React.ReactElement}) => {
  return (
    (
      <div className={`${className || ''}`}>
        <div className="w-full px-4 md:px-0 md:mx-auto md:w-11/12">
          {children}
        </div>
      </div>
    )
  )
}

export default Container
