import React, { useContext, useEffect, useState } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { SelectOptionType, PttModelField } from "../../types";
import Field from "./Field";
import { GlobalContext } from "../../contexts/GlobalContext";

interface SelectType extends PttModelField {
  options: {
    label: string;
    type: "static" | "country";
    multiple?: boolean;
    model?: string;
    options: { [key: string]: string };
  };
}

const Select = ({ field }: { field: SelectType }) => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors, data } =
    useContext(EditDataContext);

  const { getLiteral } = useContext(GlobalContext);

  const language = getLanguage({ field });
  const value = getValue({
    field,
    defaultValue: field?.options?.multiple ? [] : undefined,
  });
  const errors = getErrors({ field });

  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const shouldAddMultipleValue = (val: string[], v: string) => {
    if (!val.includes(v)) {
      val.push(v);
    }
    return [...val];
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    value[language] = field?.options?.multiple
      ? shouldAddMultipleValue(value[language] as string[], e.target.value)
      : e.target.value;
    updateStored(field, value);
  };

  const removeItem = (id: string) => {
    const valueItems = value[language] as string[];
    value[language] = valueItems.filter((item: string) => item !== id);
    updateStored(field, value);
  };

  useEffect(() => {
    if (
      (!field?.options?.type || field?.options?.type === "static") &&
      field?.options?.options
    ) {
      setOptions((o: SelectOptionType[]) => [
        { _id: "", title: field?.options?.label },
        ...Object.keys(field?.options?.options).map((key: string) => ({
          _id: key,
          title: `${field?.options?.options[key]}`,
        })),
      ]);
    } else {
      setOptions([{ _id: "", title: "Loading..." }]);
      if (
        typeof field?.options?.model !== "undefined" &&
        data &&
        typeof data.find !== "undefined" &&
        typeof data.find[field?.options?.model] !== "undefined"
      ) {
        setOptions([
          { _id: "", title: field?.options?.label },
          ...data.find[field?.options?.model],
        ]);
      }
    }
  }, [field]);

  useEffect(() => {
    if (
      field?.options?.type === "static" &&
      !field.translatable &&
      typeof value?.default === "undefined" &&
      options.length
    ) {
      value[language] = options[0]._id;
      updateStored(field, value);
    }
  }, [field]);

  const multipleOption = (id: string) => {
    let option = options.find((item) => item._id === id);
    return (
      <div
        key={id}
        className="flex-grow-0 flex-shrink-0 p-1 px-2 mb-1 mr-1 text-xs bg-white border border-grayLight"
      >
        {option ? option.title : `Not found ${id}`}
        <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
          ✕
        </span>
      </div>
    );
  };

  return (
    <Field field={field}>
      <div>
        <>
          <div className="relative">
            <div className="absolute right-0 py-1 mr-2">&#8595;</div>
            <select
              name={field.name}
              className={`w-full px-2 py-1 border border-grayLight ${
                showErrors && errors.length > 0 ? "border border-error" : ""
              } outline-none appearance-none`}
              placeholder={field?.options?.label}
              value={
                field?.options?.multiple ? "-1" : (value[language] as string[])
              }
              onChange={onChangeSelect}
            >
              {options.map((option) => (
                <option
                  key={option._id + option.title}
                  disabled={
                    option.disabled ||
                    !!(
                      field?.options?.multiple &&
                      value &&
                      value[language] &&
                      (value[language] as string[]).includes(option._id)
                    )
                  }
                  value={option._id}
                >
                  {option.title}
                  {option?.status && ` (${getLiteral(option?.status)})`}
                </option>
              ))}
            </select>
          </div>
          {field?.options?.multiple &&
            value &&
            value[language] &&
            Array.isArray(value[language]) &&
            (value[language] as string[]).length > 0 &&
            options.length > 1 && (
              <div className="flex flex-wrap mt-2">
                {(value[language] as string[]).map(
                  (id) => id.length > 0 && multipleOption(id)
                )}
              </div>
            )}
        </>
      </div>
    </Field>
  );
};

export default Select;
