import dayjs from "dayjs";

const printValue = ({
  item,
  key,
}: {
  item: { [key: string]: unknown };
  key: string;
}) => {
  const value = item[key];

  if (Array.isArray(value)) {
    return value.join(", ");
  }

  if (key.includes("date")) {
    return dayjs(item[key] as string).format("DD/MM/YYYY");
  }
  if (key.includes("datetime")) {
    return dayjs(item[key] as string).format("DD/MM/YYYY HH:mm");
  }
  if (key.includes("time")) {
    return dayjs(item[key] as string).format("HH:mm");
  }
  return item[key];
};

export default printValue;
