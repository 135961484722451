import React, { useContext } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttModelField } from '../../types'
import Field from './Field'

interface NumberFieldType extends PttModelField {
  options: {
    decimals?: number
    label: string
  }
}

const Number = ({
  field,
}: {
  field: NumberFieldType
}) => {

  const { updateStored, getValue, getErrors, getLanguage, showErrors } = useContext(EditDataContext)
  
  const language = getLanguage({ field })
  const value = getValue({ field })
  const errors = getErrors({ field })

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value || ''
    let newNumberValue
    if (newValue && field?.options?.decimals && field?.options?.decimals > 0) {
      newNumberValue = parseFloat(newValue)
    } else if (newValue) {
      newNumberValue = parseInt(newValue, 10)
    }
    value[language] = newNumberValue
    updateStored(field, value)
  }

  return (
    <Field
      field={field}
    >
      <input
        name={field.name}
        className={`w-full px-2 py-1 ${showErrors && errors.length > 0 ? 'border-error' : ''
          } outline-none placeholder-gray placeholder-opacity-50 border border-grayLight`}
        type="number"
        step={`${field?.options?.decimals ? `0.${field?.options?.decimals}` : '1'}`}
        placeholder={field.options.label}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
      />
    </Field>
  )
}

export default Number
