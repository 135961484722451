import { useContext, useEffect, useState } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Container from "../components/Container";
import TopBar from "../components/TopBar";
import { EditDataContext } from "../contexts/EditDataContext";
import { GlobalContext } from "../contexts/GlobalContext";
import { AppConfigMenuItemType } from "../types";
import Dashboard from "./Dashboard";
import Edit from "./Edit";
import List from "./List";
import Sitemap from "./Sitemap";

const Main = () => {
  const location = useLocation();

  const { setData } = useContext(EditDataContext);
  const { appConfig, logout, getUser, getLiteral } = useContext(GlobalContext);

  useEffect(() => {
    setData(undefined);
  }, [location, setData]);

  const isMenuItemActive = (item: AppConfigMenuItemType) => {
    let active = false;
    if (typeof item.items !== "undefined") {
      item?.items.forEach((subitem) => {
        const section = location.pathname.split("/")[3];
        if (!active && section === subitem.model) {
          active = true;
        }
      });
    }
    if (!active) {
      return location.pathname.includes(item.model);
    }
    return active;
  };

  const Menu = () => (
    <>
      <div className="md:w-2/12"></div>
      <nav className="px-4 border-r md:fixed md:h-screen md:top-0 md:left-0 md:bottom-0 md:px-0 md:w-2/12 md:flex md:flex-col bg-grayLighter border-grayLight">
        <div className="py-2 md:pb-12 md:flex-1 md:overflow-scroll md:py-0">
          <div className="hidden md:block">
            <TopBar>
              <h1 className="hidden px-4 font-medium uppercase md:block">
                <NavLink to="/">{appConfig && appConfig.app.title}</NavLink>
              </h1>
            </TopBar>
          </div>
          {appConfig && (
            <ul className="flex flex-wrap md:block">
              {appConfig.menu.map((item, index) => (
                <li key={index} className="mr-2 md:mb-2 md:mr-0">
                  <NavLink
                    className={`md:block md:mx-4 rounded md:p-2 md:py-1 hover:bg-grayLight ${
                      isMenuItemActive(item) ? "bg-grayLight" : ""
                    }`}
                    to={item.path || `/admin/list/${item.model}`}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
              {!appConfig?.extra?.hideSitemap && (
                <li className="mr-2 md:mb-2 md:mr-0">
                  <NavLink
                    className={`md:block md:mx-4 rounded md:p-2 md:py-1 hover:bg-grayLight`}
                    to={`/admin/sitemap`}
                  >
                    {getLiteral("sitemap")}
                  </NavLink>
                </li>
              )}
            </ul>
          )}
        </div>
        <div className="items-center hidden py-4 text-xs border-t md:block md:absolute md:bottom-0 md:left-0 md:right-0 border-grayLight md:mx-4">
          <div>
            👋 {getLiteral("hi")}{" "}
            <span className="capitalize">{getUser()?.name}</span>
          </div>
          <div onClick={logout} className="cursor-pointer text-gray">
            {getLiteral("logout")}
          </div>
        </div>
      </nav>
    </>
  );

  const TopMenu = () => (
    <>
      {appConfig?.menu &&
        appConfig?.menu.map((item) => {
          if (isMenuItemActive(item)) {
            return (
              <Container key={item.model}>
                <ul className="flex text-sm">
                  {item.items &&
                    item.items.map((subitem, index) => (
                      <li key={index} className="mr-4">
                        <NavLink
                          className={({ isActive }) =>
                            `hover:text-primary ${
                              isActive || pathNameArr.includes(subitem.model)
                                ? ""
                                : "font-light"
                            }`
                          }
                          to={subitem.path || `/admin/list/${subitem.model}/`}
                        >
                          {subitem.title}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </Container>
            );
          }
          return null;
        })}
    </>
  );

  const [pathNameArr, setPathNameArr] = useState(location.pathname.split("/"));

  useEffect(() => {
    setPathNameArr(location.pathname.split("/"));
  }, [location.pathname]);

  if (!appConfig?.user) {
    logout();
    return null;
  }

  return (
    <div className="min-h-screen md:flex">
      <Menu />
      <div className="md:w-10/12">
        <TopBar>
          <TopMenu />
        </TopBar>
        {appConfig && (
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/edit">
              <Route path=":name/:id" element={<Edit />} />
              <Route path=":name" element={<Edit />} />
            </Route>
            <Route path="/list">
              <Route path=":name" element={<List />} />
            </Route>
            <Route path="/sitemap" element={<Sitemap />} />
          </Routes>
        )}
        <div className="p-4 mt-4 text-xs md:hidden">
          <div>
            👋 {getLiteral("hi")}{" "}
            <span className="capitalize">{getUser()?.name}</span>
          </div>
          <div onClick={logout} className="cursor-pointer text-gray">
            {getLiteral("logout")}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
