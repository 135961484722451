import React, { useContext } from "react";
import { EditDataContext } from "../../contexts/EditDataContext";
import { PttFieldType } from "../../types";
import Field from "./Field";

interface ColorProps extends PttFieldType {}

const Color = ({ field }: ColorProps) => {
  const { updateStored, getValue, getErrors, getLanguage, showErrors } =
    useContext(EditDataContext);

  const language = getLanguage({ field });
  const value = getValue({ field });
  const errors = getErrors({ field });

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = `${e.target.value.replace("#", "")}`;
    updateStored(field, value);
  };

  return (
    <Field field={field}>
      <div className="flex items-stretch bg-white rounded">
        <div
          className="w-8 border border-r-0 border-grayLight"
          style={{ backgroundColor: `#${value[language]}` }}
        ></div>
        <input
          name={field.name}
          className={`w-full px-2 py-1 bg-white outline-none placeholder-gray placeholder-opacity-50 border ${
            showErrors && errors.length > 0
              ? "border-error"
              : "border-grayLight"
          }`}
          type="text"
          placeholder={field.options.label}
          value={`${value[language] || ""}`}
          onChange={onChangeText}
        />
      </div>
    </Field>
  );
};

export default Color;
