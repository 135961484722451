import React, { useContext } from 'react'
import { EditDataContext } from '../../contexts/EditDataContext'
import { PttFieldType } from '../../types'
import Field from './Field'

const Time = ({
  field,
}: PttFieldType) => {

  const { updateStored, getValue, getErrors, getLanguage, showErrors } = useContext(EditDataContext)
  
  const language = getLanguage({ field })
  const value = getValue({ field })
  const errors = getErrors({ field })

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = e.target.value
    updateStored(field, value)
  }

  return (
    <Field
      field={field}
    >
      <input
        name={field.name}
        className={`w-full border border-grayLight px-2 py-1 ${showErrors && errors.length > 0 ? 'border-error' : ''
          } outline-none placeholder-gray placeholder-opacity-50`}
        type="time"
        placeholder={field?.options?.label}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
      />
    </Field>
  )
}

export default Time
