import { useContext, useState } from "react"
import Form from "./Form"

import { PttFieldType } from "../../types"
import Field from "./Field"

import { EditDataContext } from '../../contexts/EditDataContext'
import { PttModelField } from "../../types"

interface ModelFieldType extends PttFieldType {
  name: string
  type: string
  [key: string]: unknown
  parentField?: PttModelField
  options: {
    label: string
    model: string
    dropdown: boolean
    dropdownState: string
    defaultValues: {[key: string]: unknown}
  }
}

const Model = ({
  field,
}: {
  field: ModelFieldType
}) => {

  const { data } = useContext(EditDataContext)

  const [showContent, setShowContent] = useState(
    field.options?.dropdown && field.options.dropdownState === "hidden" ? false : true
  )

  if (!data || !data.models) return null

  const { models } = data

  return (
    <Field
      field={field}
    >
      <div
        className={`${
          field?.options?.dropdown ? "cursor-pointer" : ''
        }`}
      >
        <div
          className={`bg-white overflow-hidden p-2 pb-0 md:p-4`}
        >
          <div
            className="flex items-center justify-between mb-4 text-xs font-medium uppercase select-none border-bottom"
            onClick={() => {
              if (field?.options?.dropdown) {
                setShowContent(!showContent)
              }
            }}
          >
            <span>{field.options.label}</span>
            {field?.options?.dropdown && (
              <div className={`w-4`}>
                <svg
                  className="fill-current"
                  style={{
                    transform: `${
                      showContent ? "rotate(-90deg)" : "rotate(90deg)"
                    }`,
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512.002 512.002"
                >
                  <path d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z" />
                </svg>
              </div>
            )}
          </div>
          <div className={`${
            field.options?.dropdown && !showContent ? "h-0" : ""
          }`}>
            <Form
              name={field?.options?.model}
              fields={models[field?.options?.model]}
              parentField={field}
            />
          </div>
        </div>
      </div>
    </Field>
  )
}

export default Model
